<template>
      <div class = "buyer">
          <a-spin :spinning="spinning">
                <cTop></cTop>
            <div class = "body">
                  <div class = "page-wrap">
                      <a-table style="width: 1200px" :columns="columns" :data-source="list" :pagination="false">
                        <span slot="operation" slot-scope="text, record, idx">
                            <a @click="download(record.id, idx)">下载</a>
                        </span>
                      </a-table>
                  </div>
                      <div class="pagination" v-if="total > 0">
                    <a-pagination :current = "queryParams.page" :total = "total" :pageSize = "queryParams.pageSize"
                                  @change="changePage" :show-total="total => `共 ${total} 条记录`"/>
                </div>
            </div>
            <cFooter></cFooter>
            </a-spin>
      </div>
</template>

<script>
      import cFooter from '../component/footer';
      import cTop from '../component/top';

      import service from "../../utils/request";
      export default {
            name: "Download",
            components: {
                cTop,
                  cFooter,
            },
          data(){
                return {
                    spinning: false,
                    columns:[{
                        title: '文件名称',
                        dataIndex: 'title',
                        scopedSlots: {customRender: 'title'},
                    },
                        {
                            title: '文件来源',
                            dataIndex: 'source_desc',
                            scopedSlots: {
                                customRender: 'source_desc'
                            }
                        },
                        {
                            title: '文件大小',
                            dataIndex: 'file_size',
                        },
                        {
                            title: '下载次数',
                            dataIndex: 'download_times',
                        },
                        {title:'生成时间', dataIndex:'created_at'},
                        {title: '操作', key: 'operation', scopedSlots: {customRender: 'operation'}, fixed: 'right'},],
                    list:[],
                    queryParams:{
                        page:1,
                        pageSize:10
                    },
                    total:0
                }
          },
          created() {
                this.getList();
          },
          methods:{
                getList(){
                    this.spinning = true;
                    service.post(service.uri.user.fileLog, this.queryParams).then(res => {
                        this.spinning = false;
                        if(res.code == 200){
                            this.list = res.data.list;
                            this.total = res.data.total;
                        }
                    });
                },
              download(id, idx){
                    this.spinning = true;
                  service.post(service.uri.user.download, {id:id}).then(res => {
                      this.spinning = false;
                      if(res.code == 200){
                          this.list[idx].download_times++;
                          window.open(res.data);
                      }
                  });
              }
          }
      }
</script>

<style lang="scss" scoped>
      .body {
            background: #fbfbfb;
          width: 1200px;
          margin: auto;
      }

      .page-wrap {
            width: 1200px;
            margin: 0 auto;
      }
      a {
            color: #de0000;
            text-decoration: none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            transition: color .3s;
            -webkit-text-decoration-skip: objects;
      }

      dt {
            font-weight: 500;
      }

      dd {
            margin-bottom: 0.5em;
            margin-left: 0;
      }

      .body {
            background: #fbfbfb;

            .page-wrap {
                  width: 1200px;
                  margin: 0 auto;
                  display: flex;
            }
      }
</style>